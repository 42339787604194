@import "variables.scss"; @import "mixins.scss"; @import "media.scss";
.sectionWrap {
  padding-top: var(--sectionPaddingLarge);
  padding-bottom: var(--sectionPaddingLarge);
}

.columnPadding {
  @include for-tablet-landscape {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.selectorsWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  @include for-tablet-landscape {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > .tablist:first-child {
      padding: 16px 0 8px 0;
    }

    > .tablist:last-child {
      padding: 0 0 16px 0;
    }
  }
}

.tablist {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  padding: 16px 0;
}

.priceCard {
  position: relative;
  background-color: var(--grey2);
  border-radius: 24px;
  padding: 24px;

  @include for-tablet-landscape {
    border-radius: 12px;
  }

  display: flex;
  flex-direction: column;
}

.sizeLine {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: flex-end;
  margin-bottom: 8px;

  > h3 {
    
  @include for-small-screens {
    font-size: 44px;
  }

  }
}

.mediaWrap {
  padding-top: 32px;
  padding-bottom: 16px;
}
.bestFitWrap,
.perBinWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 40px;
}

.bestFitParagraph {
  white-space: pre-line;
}

.savingsWrap,
.costWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.savingsLabel {
  font-size: 44px;
}

.contactButton {
  width: fit-content;
  align-self: flex-end;
}

.figure {
  height: 400px;
  max-height: 100%;
  width: 100%;
  position: relative;
}
